import { PageViewType } from '$lib/models/page.models';
import { writable } from 'svelte/store';

export const loadedServiceGroup = writable<string>('');
export const currentServiceGroup = writable(null);

export const hasFilter = writable(false);
export const showFilter = writable(false);

export const showSideBar = writable(false);

export const pageContentView = writable<PageViewType>(PageViewType.LIST);
