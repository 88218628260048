export enum PageViewType {
    LIST,
    MAP,
    OTHER
}

export interface PageTagValues {
    hover?: string;
    imagePath?: string;
    keywords?: string;
    seoDescription: string;
    seoImgPath: string;
    seoImgHeight: number;
    seoImgType?: string;
    seoImgWidth: number;
    seoTitle: string;
    title?: string;
    urlPath: string;
}

export interface PageMetaTag {
    content: string | number;
    property: string;
}
